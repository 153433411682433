.navbar {
  background: var(--Liner-new, linear-gradient(180deg, #6ec0ba 0%, #242f5e 100%, rgba(36, 47, 94, 0.62) 100%));
  box-shadow: 0px 7px 37px 1px rgba(111, 193, 188, 0.13);
  min-width: none;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
}

.navbar-large {
  min-width: 444px;
}

.navbar-small {
  width: 9%;
  min-width: 158px;
}

.navbar-logo {
  display: flex;
  margin-left: 80px;
  margin-right: 45px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo-small {
    display: flex;
    margin-left: 22px;
    margin-right: 23px;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;
}
.navbar-logo > img:hover,
.navbar-logo-small > img:hover {
  cursor: pointer;
}
.navbar-wallets-container {
  margin-top: 56px;
  margin-bottom: 48px;
  /* height: 317px; */
  padding-left: 54px;
  padding-right: 37px;
  color: var(--black-0, #fff);
}

.navbar-wallets-container-small {
  margin-top: 67px;
  margin-bottom: 48px;
  padding-left: 22px;
  padding-right: 22px;
  color: var(--black-0, #fff);
}

/* .navbar-menu-container{

} */
.navbar-menu-header {
  color: var(--button-filled-color);
  margin-bottom: 32px;
  padding-left: 80px;
}
.navbar-menu-header-small {
  color: var(--button-filled-color);
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-menu-item {
  color: var(--button-filled-color);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  padding-left: 19%;
  height: 71px;
  margin-bottom: 4px;
}
.navbar-menu-item:hover {
  background-color: #ffffff3b;
  color: black;
}
.navbar-menu-item-small {
  color: var(--button-filled-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 71px;
}

.navbar-menu-item-small:hover {
  background-color: #ffffff3b;
  color: black;
}
.navbar-menu-item-small svg {
  width: 2.25rem;
  height: 2.25rem;
}
.item-active {
  background-color: #ffffff3b;
  border-left: 7px solid white;
  padding-left: 17%;
  color: black;
}
/* padding on the opposite side to center the icon */
.item-active-small {
  background-color: #ffffff3b;
  border-left: 4px solid white;
  color: black;
  padding-right: 4px;
}
/* .navbar-menu-item:hover img:hover{
    color: black;

} */

.wallet-header {
  display: flex;
  padding-left: 26px;
  align-items: center;
  gap: 14px;
  color: #fff;
}

.wallet-header-small {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #fff;
}

.hide-show-wallets {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}
