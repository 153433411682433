.styleRow {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center
}

.mb {
  margin-bottom: 32px;
}

.link {
  color: #2c405a;
  margin-bottom: 0.25rem;
}

.topMargin {
  margin-top: 16px;
}

@media screen and (max-width: 380px) {
  .topMargin {
    margin-top: 0px;
  }
}
