.popup {
  margin-left: 12%;
  margin-top: 30px;
  margin-bottom: 50px;
  width: 80%;
  padding: 10px 20px 20px;
  border-radius: 20px;
  background: linear-gradient(179deg, rgba(255, 255, 255, 0.00) -32.34%, #FFF 114.38%);
}

.popup-title {
  color: var(--t-4bsecondary);
  margin: 0 0 10px;
}

.popup-body {
  margin: 0 0 15px;
  color: var(--text-full-color);
}

.close-btn {
  cursor: pointer;
}

.open-btn {
  color: var(--t-4bsecondary);
  cursor: pointer;
}

.open-img {
  margin-left: 10px;
}


