.selectedId {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 26px;
  max-width: 353px;
  color: var(--text-full-color);
}

.secondId {
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 26px;
  max-width: 353px;
  color: var(--text-full-color);
}

.secondId:hover {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 26px;
  max-width: 353px;
}

.auto-layout-vertical {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
}

.div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
}

.equity-value {
  display: flex;
  gap: 8px;
}

.color-red {
  color: #c36167;
}

.color-green {
  color: #2fa061;
}

.text-wrapper {
  color: var(--t-4bsecondary);
}

.text-wrapper-2-selectedId {
  color: var(--black-400);
}

.text-wrapper-2-secondId {
  color: var(--Black-0);
}

.auto-layout-vertical-2 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-end;
}

.text-wrapper-3-selectedId {
  color: var(--t-4bsecondary);
  text-wrap: nowrap;
}

.text-wrapper-3-secondId {
  color: var(--Black-0);
  text-wrap: nowrap;
}

.text-wrapper-5-selectedId {
  color: var(--black-500);
}

.text-wrapper-5-secondId {
  color: var(--Black-0);
}

.div-2 {
  align-items: center;
  display: flex;
  padding: 0px 4px;
  height: 24px;
  justify-content: flex-end;
  width: 100%;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
}

.img {
  height: 24px;
  width: 24px;
}

.text-wrapper-4 {
  color: rgba(47, 160, 97, 1);
}

/* menuSmall */
.selectedId-small {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 11px;
}

.secondId-small {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px;
  gap: 10px;
}

.secondId-small:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px;
  gap: 10px;
}
