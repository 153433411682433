.language-dropdown {
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  color: black;
}

.dropdown-menu {
  width: 200px;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
}

.dropdown-menu.dropdown-menu.show {
  display: flex;
}

.languages-dropdown-button-text {
  color: black;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 6px 12px 6px 12px;
  align-items: center;
  gap: 8px;
  color: black;
}

.dropdown-item:hover {
  background-color: var(--bg-main-hover-color);
  color: black;
}

.flags-img {
  border-radius: 20px;
}
