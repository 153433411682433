.div-2 {
  align-items: center;
  display: flex;
  padding: 0px 4px;
  height: 24px;
  justify-content: flex-end;
  width: 100%;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.50);
}
