@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

$app-body-color: #ecf5fd;
// $my-primary-color: #1b2838;
$my-primary-color: rgb(44, 64, 90);
$my-secondary-color: rgb(110, 192, 187);
$mu-font-family-sans-serif: 'Open Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$spacer: 1rem !default;

:root {
  --button-empty-border-color: #aaaaaa;
  --button-empty-color: #606060;
  --button-filled-color: #ffffff;
  --button-filled-bg-color: #6ec0ba;
  --button-filled-bg-color-hover: #a0e4df;
  --modal-footer-bg-color: #e8fdfb;
  --modal-title-color: #2b788d;
  --modal-graph-color: #2b788d;
  --modal-graph-rgba-color: rgba(43, 120, 141, 0.28);
  --bg-main-hover-color: #edf5f8;

  --text-full-color: black;
  --text-lighter-color: #aaaaaa;

  --Black-0: #fff;
  --black-200: #dadada;
  --black-300: #aaa;
  --black-400: rgba(96, 96, 96, 1);
  --black-500: rgba(0, 0, 0, 1);
  --t-4bsecondary: rgba(36, 48, 94, 1);
  --t-4bsecondary-1: rgba(43, 120, 141, 1);
  --Blue-0: #f5fffe;
  --Blue-100: #e8fdfb;

  --text-button-modal-close: 'Close';

  --bs-body-color: initial;
  --custom-card-cap-padding-y: 12px;
  --custom-card-cap-padding-x: initial;
  --bs-link-color: #2c405a;
  --bs-link-hover-color: #43948f;

  --font-size-desk-h1: 28px;
  --font-size-desk-h2: 24px;
  --font-size-desk-h3: 20px;
  --font-size-desk-h4: 18px;
  --font-size-desk-h4-semibold: 18px;
  --font-size-desk-regular: 16px;
  --font-size-desk-semibold: 16px;
  --font-size-desk-small: 14px;
  --font-size-desk-small-semibold: 14px;
  --font-size-desk-inputs: 12px;
  --font-size-desk-check-boxes-and-others-regular: 12px;

  --toastify-color-error: #fedcdc;
  --toastify-color-info: #3498db;
  --toastify-color-success: #ddf1e2;
  --toastify-color-warning: #f1c40f;

  --toastify-text-color-info: black;
  --toastify-text-color-success: black;
  --toastify-text-color-warning: black;
  --toastify-text-color-error: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  outline: none !important;
  box-shadow: none !important;
}

body {
  font-family: $mu-font-family-sans-serif;
  // background-color: $app-body-color !important;
  // background-color: rgb(243, 243, 243);
  margin: 0;
  color: $my-primary-color;
  // overflow: -webkit-paged-y;
  //overflow-y: hidden;
  height: 100vh;
}

.main {
  color: var(--black-500)
}

// Hide scrollbar Chrome, Safari, Opera
// body::-webkit-scrollbar {
//   display: none;
// }

// // Hide scrollbar Firefox
// html {
//   scrollbar-width: none;
// }

/* Here we will handle all font sizes to be used throughout the website */
.text-h1 {
  /* desktop/H1 */
  font-family: Open Sans;
  font-size: var(--font-size-desk-h1);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-h2 {
  /* desktop/H2 */
  font-family: Open Sans;
  font-size: var(--font-size-desk-h2);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-h3 {
  /* desktop/H3 */
  font-family: Open Sans;
  font-size: var(--font-size-desk-h3);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-h4 {
  /* desktop/H4: Regular */
  font-family: Open Sans;
  font-size: var(--font-size-desk-h4);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-h4-semibold {
  /* desktop/H4: SemiBold */
  font-family: Open Sans;
  font-size: var(--font-size-desk-h4-semibold);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-regular {
  /* desktop/Text: Regular */
  font-family: Open Sans;
  font-size: var(--font-size-desk-regular);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-semibold {
  /* desktop/Text: SemiBold */
  font-family: Open Sans;
  font-size: var(--font-size-desk-semibold);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-small {
  /* desktop/small: Regular */
  font-family: Open Sans;
  font-size: var(--font-size-desk-small);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-small-semibold {
  /* desktop/small, semibold */
  font-family: Open Sans;
  font-size: var(--font-size-desk-small-semibold);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-inputs {
  /* desktop/check_boxes_and_others: Regular */
  font-family: Open Sans;
  font-size: var(--font-size-desk-inputs);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-primary {
  background-color: $my-primary-color;
  border-color: $my-primary-color;
}

.btn.btn-primary:disabled {
  background-color: rgba(182, 196, 214, 0.65);
  border-color: rgba(182, 196, 214, 0.65);
}

.page-item.active .page-link {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1c7087 !important;
  opacity: 0.65;
}

.filter-buttons .page-link {
  border-radius: 4px;
}

.page-link {
  color: $my-primary-color;
}

.nav-link:not(.dropdown-toggle) {
  color: $my-primary-color;
  border-color: #e9ecef #e9ecef #dee2e6 !important;
}

.dropdown-toggle::after {
  display: none;
}

select.form-select {
  width: 10rem;
  border-bottom: 1px solid #333;
}

.form-group {
  margin: 5px 0 5px 0 !important;
}

span.page-link:hover {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

tspan {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

li.filter-title span {
  color: #2c405a !important;
  font-weight: 700;
  min-width: 7rem;
  margin-right: 0.25rem;
  background-color: #f3f3f3 !important;
}

.react-datepicker__input-container:not(.new-datepicker *) input:not(.new-datepicker *) {
  color: #6c757d;
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #333 !important;
  padding: 0.25rem 0.5rem;
  margin-top: -3px;
  margin-right: 0.45rem;
}

.form-control {
  display: inline !important;
  border-bottom: 1px solid #333;
}
.form-control:not(.form-floating *):focus {
  display: inline !important;
  border-bottom: 1px solid #2d6999;
}

.form-control[readonly],
.form-select[disabled] {
  background-color: white;
  color: #adb5bd;
  border-bottom: 1px solid #adb5bd;
}

.form-control[readonly]::placeholder {
  color: #adb5bd;
}

.read-only {
  color: #adb5bd;
}

#read-only {
  background-color: white;
  color: #adb5bd;
  border-bottom: 1px solid #adb5bd;
}

#sortby-select {
  padding-left: 8px !important;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
}

a {
  color: #007bff;
  text-decoration: none; /* no underline */
  font-weight: 400;
  // opacity: 0.65;
}

.adminOwnFunds {
  color: #2c405a !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
  padding: 8px;
}

.admin {
  color: #56bdb6 !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
  opacity: 0.95;
}
.admin:hover {
  color: #006a87 !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
}
.admin.btn-link:hover {
  color: #006a87 !important;
  text-decoration: none; /* no underline */
  font-weight: 400;
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

a:hover {
  color: #43948f;
  text-decoration: none;
}

.card-header-admin {
  padding: 0.75rem 1.25rem 8px 1rem !important;
}

.nav-item:hover > .nav-link:hover:not(.dropdown-toggle) {
  color: #43948f;
}

.settings-dropdown a:not(.list-settings) {
  border: 1px solid #e9ecef !important;
}

.input-mobile {
  margin-bottom: 0.5rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background-color: white;
  display: block !important;
}

.admin-bg-color {
  background-color: rgb(243, 243, 243);
}

@media only screen and (max-width: 1000px) {
  .admin-bg-color {
    background-color: white !important;
  }
}

// @media only screen and (min-width: 1001px) and (max-width: 4000px) {
.admin-input-color,
#read-only {
  background-color: rgb(255, 255, 255) !important;
}
// }

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

input[type='text']:not(.search-new):not(.input-new):not(.new):not(.floating-input-no-border):not(.input-mobile):not(
    .new-datepicker *
  ),
textarea {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 8px;
}

select {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 0px !important;
}

th.position-relative {
  cursor: pointer;
}

.highchdarts-root {
  max-width: 96%;
  display: inline-block;
}

.pass-reg-row {
  max-width: 80% !important;
  display: flex;
  align-items: center;
}

.datepicker-credit-investor {
  margin: 0 !important;
  padding: 0 !important;
}

.modal-header,
.modal-footer {
  background-color: #fafbfc !important;
  padding: 12px 48px;
}
.modal-body {
  padding: 24px 48px 16px !important;
}

.btn-link {
  text-decoration: none !important;
  opacity: 0.65;
  color: #007bff !important;
}

.admin.btn-link {
  text-decoration: none !important;
  opacity: 0.95;
  color: #56bdb6 !important;
  font-weight: 400;
}

.feeAccounts.btn-link {
  text-decoration: none !important;
  opacity: 0.75;
  color: #56bdb6 !important;
}
.feeAccounts.btn-link:hover {
  text-decoration: none !important;
  color: #006a87 !important;
}

.link-btn {
  text-decoration: none !important;
  color: #007bff !important;
  opacity: 0.8;
}

.input:not(.form-floating *):not(.new-datepicker *),
.mx-auto,
.form-control:not(.form-floating *):not(.new-datepicker *):not(.new),
.form-control-sm {
  padding-left: 0px !important;
}

// .input:not(.form-floating *), .mx-auto, .form-control:not(.form-floating *), .form-control-sm{
//   padding-left: 0px;
// }

input:not(.form-floating *):not(.form-check-input):not(.new-datepicker *):not(.search-new):not(.new),
select:not(.round-blue):not(.new-datepicker *) {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  // border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.react-datepicker__input-container:not(.new-datepicker *) input:not(.new-datepicker *) {
  max-width: 100px !important;
}

.react-datepicker__input-container:not(.new-datepicker *) .datePicker-filter:not(.new-datepicker *) {
  max-width: 113px !important;
  font-size: 16px;
  padding-left: 0px !important;
}

.app-navbar {
  img {
    border-radius: 0px;
  }
}

.pb-6 {
  padding-bottom: ($spacer * 4) !important;
}
.pb-32 {
  padding-bottom: ($spacer * 2) !important;
}
.pb-36 {
  padding-bottom: ($spacer * 2.25) !important;
}
.pt-32 {
  padding-top: ($spacer * 2) !important;
}

.ml-3 {
  margin-left: 16px;
}

.mr-3 {
  margin-right: 16px;
}

.ml-auto {
  margin-left: auto;
}

.dropdown-item:hover {
  background-color: #5c8fbf;
  color: white;
}

.search-icon {
  position: absolute;
  margin-left: 300px;
  width: auto;
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0px 0px;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-bottom: 1px solid #333;
}

#graphicsPeriodSelect {
  padding-left: 8px !important;
}
.datePicker {
  padding-left: 8px !important;
}

.search-input {
  padding-left: 8px !important;
}

.modal-body {
  padding-bottom: 48px !important;
  padding-top: 32px !important;
}

.editUser.modal-body {
  padding-top: 8px !important;
}

.modal-dialog.modal-lg.commissions {
  width: 600px;
}

.modal-dialog.modal-lg.modal-filtered {
  width: 600px;
}

.modal-dialog.so-modal {
  width: 776px;
}

.invalid-img.is-invalid {
  background-size: 12px 12px;
}

.modal-filtered .modal-content {
  height: 829px;
}

.modal-dialog.modal-lg.settings {
  width: 560px;
}

.ordersModalSize {
  max-width: 700px;
}

.fa-users.fa-lg,
.fa-hand-holding-dollar,
.fa-user-tie,
.fa-percent,
.fa-desktop {
  color: #2c405a !important;
}

.licence {
  padding: 20px 27px;
}

.shadow-active-journal {
  bottom: 0;
  left: 0%;
  position: fixed;
  right: 0;
  top: 0%;
  z-index: 700;
  background: rgba(9, 30, 66, 0.54);
}

.shadow-active-sso {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 700;
  background: #f3f3f3;
}

.sidebar-show .shadow-active-journal-icon {
  margin-left: 115px;
  // transition: 0.3s;
}

.sidebar-show .shadow-active-journal-text {
  margin-left: 80px !important;
  // transition: 0.3s;
}

.shadow-journal {
  transition: opacity 1000ms ease;
  opacity: 1;
}

// .form-check .form-check-input:checked~.form-check-label::before{
//   background-color:red !important;
// }
// .form-check-input:checked~.form-check-label::before{
//   color: #fff !important;
//   background-color:red !important;
// }

// .custom-control-label:before{
//   background-color:white;
// }
// .custom-control-input {
//   border-radius: 0px !important;
// }
// .custom-checkbox .custom-control-input:checked~.custom-control-label::before{
//   background-color: #52A7A1;
//   margin-top: '5px';
//   border-color: #52A7A1;
// }

// input[type=checkbox]{
//   display:none;
// }
// label{
//   cursor:pointer;
//   display:inline-block;
//   width:200px;
//   padding:5px 20px 5px 5px;
//   position:relative;
// }
// label:after{
//   content:'';
//   display:block;
//   position:absolute;
//   width:15px;
//   height:15px;
//   border:1px solid blue;
//   border-radius: 3px;
//   top:8px;
//   right:5px;
//   background-color:#fff;
// }
// // input[type=checkbox]:checked + label{
// //   background-color: #aaa;
// // }
// input[type=checkbox]:checked + label:after{
//   background-color: blue;
// }

.ellipsis-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h-60px > a {
  margin: 0 auto !important;
  margin-top: 6px !important;
  position: relative !important;
  margin-left: 11px !important;
  text-decoration: none;
}

.nav-item .ml-navbar {
  width: 60px;
}

.pamm-navbar-exit {
  padding-top: 16px;
  padding-right: 32px !important;
}

.pamm-navbar-customer-portal {
  padding-top: 14px;
  padding-right: 32px;
}

.lang-dropdown .dropdown-menu.show.dropdown-menu-right {
  min-width: 8.5rem;
}

.lang-dropdown .dropdown-menu.show.dropdown-menu-right > a {
  padding-left: 1rem;
}

.lang-dropdown .dropdown-toggle.nav-link {
  padding-right: 16px !important;
  margin-right: 30px;
}
#languages-dropdown-container.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#languages-dropdown.btn-primary {
  background-color: transparent;
  border-color: transparent;
}
.form-floating > .form-control {
  border: #dadada solid 1px;
  border-radius: 4px;
}
.form-floating > .form-control:focus {
  border: #2b788d solid 2px;
  border-radius: 4px;
  box-shadow: none;
}

.form-floating > .form-control.is-invalid {
  border: #c36167 solid 2px;
  border-radius: 4px;
  padding-left: 14px;
}

.form-floating > .form-control.floating-input-no-border {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #dadada;
  padding-left: 2px;
}
.form-floating > .form-control.floating-input-no-border:focus {
  border-bottom: 1px solid #2b788d;
}
.form-floating > .form-control.floating-input-no-border.is-invalid {
  border-bottom: 1px solid #6167;
}

input.floating-input-no-border.form-control + label {
  padding-left: 0px;
  color: var(--button-empty-color);
}

.floating-input-no-border:focus + label,
.floating-input-no-border:not(:placeholder-shown) + label {
  margin-left: 3.5px;
}

.new-datepicker {
  position: relative;
  height: 58px;
}
// DatePicker header
.new-datepicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border: none;
  background-color: inherit;
  padding: 0px;
}
// DatePicker Day
.new-datepicker .react-datepicker__day {
  width: 36px;
  height: 36px;
  line-height: 2.3rem;
  margin: 0px;
}
.new-datepicker .react-datepicker__day:hover {
  background-color: var(--button-filled-bg-color-hover);
  border-radius: 50%;
}
// DatePicker Day selected
.new-datepicker div.react-datepicker__day--selected {
  background-color: var(--button-filled-bg-color);
  border-radius: 50%;
  color: var(--button-filled-color);
}
.new-datepicker div.react-datepicker__day--selected:hover {
  background-color: var(--button-filled-bg-color);
}
.new-datepicker div.react-datepicker__day--keyboard-selected {
  background-color: var(--button-filled-bg-color);
  border-radius: 50%;
  line-height: 2.3em;
  color: var(--button-filled-color);
}
.new-datepicker div.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--button-filled-bg-color-hover);
}
// DatePicker Today "Pre-selected"
.new-datepicker
  div.react-datepicker__day.react-datepicker__day--today:not(.react-datepicker__day--in-range):not(
    .react-datepicker__day--in-selecting-range
  ) {
  position: relative;
  border: 1px var(--button-filled-bg-color) solid;
  border-radius: 100%;
}

// DatePicker Range mode (start-end), style for the range between
.new-datepicker div.react-datepicker__day.react-datepicker__day--in-selecting-range {
  background-color: var(--button-filled-bg-color-hover);
  color: var(--button-filled-color);
  border-radius: 0px;
}
// DatePicker Range mode (start-end) today date style
.new-datepicker .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--today {
  position: relative;
}
.new-datepicker .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--today::after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 100%;
  border-color: white;
  border: 2px solid;
  width: 36px;
  height: 36px;
}
// DatePicker range end
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end,
.new-datepicker .react-datepicker__day--range-end {
  background-color: var(--button-filled-bg-color);
  color: var(--button-filled-color);
  border-radius: 50%;
  position: relative;
}
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end::before,
.new-datepicker .react-datepicker__day--range-end::before {
  content: '';
  height: calc(2rem + 4px);
  width: calc(1rem + 4px);
  position: absolute;
  top: 0px;
  left: 0px;
  border: solid var(--button-filled-bg-color-hover);
  border-width: 5px 0px 5px 5px;
  border-radius: 0% 0%;
}
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end::after,
.new-datepicker .react-datepicker__day--range-end::after {
  content: '';
  height: calc(2rem + 4px);
  width: calc(2rem + 4px);
  position: absolute;
  top: 0px;
  left: 0px;
  border: solid var(--button-filled-bg-color);
  border-width: 5px 0px 5px 5px;
  border-radius: 50%;
}
// DatePicker range start
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
.new-datepicker .react-datepicker__day--range-start {
  background-color: var(--button-filled-bg-color);
  color: var(--button-filled-color);
  border-radius: 50%;
  position: relative;
}
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start::before,
.new-datepicker .react-datepicker__day--range-start::before {
  content: '';
  height: calc(2rem + 4px);
  width: calc(1rem + 4px);
  position: absolute;
  top: 0px;
  right: 0px;
  border: solid var(--button-filled-bg-color-hover);
  border-width: 5px 5px 5px 0px;
  border-radius: 0% 0%;
}
.new-datepicker div.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start::after,
.new-datepicker .react-datepicker__day--range-start::after {
  content: '';
  height: calc(2rem + 4px);
  width: calc(2rem + 4px);
  position: absolute;
  top: 0px;
  right: 0px;
  border: solid var(--button-filled-bg-color);
  border-width: 5px 5px 5px 0px;
  border-radius: 50%;
}
// DatePicker range start + end (when both are the same day)
.new-datepicker .react-datepicker__day--range-start.react-datepicker__day--range-end::before {
  content: none;
}
.new-datepicker .react-datepicker__day--range-start.react-datepicker__day--range-end::after {
  content: none;
}
// DatePicker range Already selected (if viewing previously selected range)
div.react-datepicker__day--in-range {
  background-color: var(--button-filled-bg-color-hover);
  border-radius: 0px;
}
.new-datepicker .react-datepicker__day.react-datepicker__day--in-range:hover {
  background-color: var(--button-filled-bg-color);
  border-radius: 0px;
}
.new-datepicker .react-datepicker__day--in-range.react-datepicker__day--today {
  position: relative;
}
.new-datepicker
  .react-datepicker__day--in-range.react-datepicker__day--today:not(.react-datepicker__day--range-end):not(
    .react-datepicker__day--range-start
  ):after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-radius: 100%;
  border-color: white;
  border: 2px solid;
  width: 36px;
  height: 36px;
}
// DatePicker month text
.new-datepicker div.react-datepicker__month-container {
  float: none;
}
.new-datepicker div.react-datepicker__month-container > div.react-datepicker__header > div.react-datepicker__current-month {
  font-family: roboto;
  font-size: 16px;
  font-weight: 400;
  margin: 26px 0px;
}
// DatePicker arrow buttons
.new-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous,
.new-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
  top: 28px;
  border: none;
}
.new-datepicker .react-datepicker__header > div.react-datepicker__day-names {
  margin-bottom: 6px;
}
// DatePicker Days names in header
.new-datepicker .react-datepicker__header > div.react-datepicker__day-names > div {
  width: 36px;
  height: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0px;
  color: #aaaaaa;
  line-height: 2.3em;
}
// DatePicker Days names in Calendar {
.new-datepicker .react-datepicker__day-name {
  line-height: normal;
  margin: 0px;
  color: var(--text-lighter-color);
}
.new-datepicker-title {
  position: absolute;
  top: 1rem;
  left: 0rem;
  z-index: 1;
  overflow: hidden;
  text-align: start;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
.new-floating-title {
  position: absolute;
  top: 1rem;
  left: 0rem;
  z-index: 1;
  width: max-content;
  overflow: hidden;
  text-align: start;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.small-datepicker-title {
  opacity: 0.65;
  transform: scale(0.85) translateY(-1.25rem) translateX(-4px);
}

.small-floating-title {
  opacity: 0.65;
  transform: scale(0.85) translateY(-1rem) translateX(-2.2rem);
}
.small-floating-title-selector {
  opacity: 0.65;
  transform: scale(0.85) translateY(-1rem) translateX(-10%);
}

.new-datepicker div.react-datepicker__input-container {
  height: 100%;
}

/* Скрыть стандартные полосы прокрутки */
::-webkit-scrollbar {
  width: 20x;
  height: 6px;
}

/* Установить цвет фона */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Установить цвет полосы прокрутки */
::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 3px;
}

/* Установить цвет полосы прокрутки при наведении */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Установить тонкую ширину полосы прокрутки */
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
}

.highcharts-admin div[data-highcharts-chart] {
  overflow-x: auto !important;
}

a > .list-group-item {
  border: none;
  color: #007bff;
}

a > .list-group-item:hover {
  border: none;
  color: #43948f;
}

.form-select {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right 1px center;
}

.form-select[disabled] {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%23adb5bd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right 1px center;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border-bottom-width: 0;
}

.d-flex.pt-2.pl-2.flex-row-reverse {
  width: auto;
  padding-right: 0px;
}

.svg-inline--fa.fa-sort {
  margin-left: 4px;
}

.horizontal-line {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.new-form-check > .form-check-input {
  border-radius: 0px;
  border: 1px solid var(--button-filled-bg-color-hover);
  box-sizing: border-box;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.new-form-check > .form-check-input:checked {
  background-color: var(--button-filled-bg-color-hover);
  border-color: var(--button-filled-bg-color-hover);
}
.new-form-check > .form-check-input:focus {
  outline: none;
  box-shadow: none;
}
.new-form-check > .form-check-input[type='radio'] {
  border-radius: 100%;
}

.highcharts-mobile div[data-highcharts-chart] {
  overflow: inherit !important;
}

input.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.text-danger {
  color: #c36167 !important;
}

.form-control.is-invalid {
  border-color: #c36167 !important;
}

// fix bootstap floating forms labels to be aligned with input
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-0.8rem) translateX(-0.2rem);
}
// Toastify css overrides
.Toastify__toast-theme--colored {
  box-shadow: none;
  border-radius: 5px;
}
.Toastify__toast-body {
  display: flex;
  align-items: center;
}
.Toastify__close-button {
  color: black;
}
.Toastify__toast-icon {
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background-color: #2fa061;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: #c36167;
}

.select-calculation-profit {
  width: 100% !important;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
  background-image: url('../src/icons/calculation-profit.svg');
  background-repeat: no-repeat;
  background-position: right center;
  border-bottom: none !important;
  padding-right: 25px !important;
}

.select-calculation-profit:focus {
  border-bottom: none !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important; /* Задаем белый фон */
  box-shadow: 0 0 0 30px white inset !important; /* Для других браузеров */
  background-color: white !important; /* Устанавливаем белый фон */
  color: black !important; /* Устанавливаем цвет текста */
}
