.topbar{
    display: flex;
    box-sizing: border-box;
    padding-top: 32px;
    padding-bottom: 23px;
    justify-content: flex-end;
    align-items: center;
}


.manual-button{
    width: 48px;
    height: 48px;
    margin-right: 17px;
    border-radius: 50%;
    background-color: var(--button-filled-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification-button{
    width: 48px;
    height: 48px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notification-button:hover svg g path{
    fill: var(--button-filled-bg-color);
    cursor: pointer;
}


.logout-button{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logout-button:hover svg g path{
    fill: var(--button-filled-bg-color);
    cursor: pointer;
}
